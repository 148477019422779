import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./editOrderModal.scss";
import { FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { APPLICATION, BASE_API } from "../../../constants";
import ApplicationContext from "../../../ApplicationContext";
import ChargeOrderModal from "../chargeOrderModal.js/ChargeOrderModal";
import VoidAmountModal from "../voidModal/VoidAmountModal";
import { CreateGuid } from "../../../utils/authenticate/CreateGUID";
import AreYouSure from "../areYouSure/AreYouSure";

function EditOrderModal(props) {
  const appContext = useContext(ApplicationContext);

  const [chargeOrder, setChargeOrder] = useState(false);
  const [voidOrder, setVoidOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAreYouSureCancel, setShowAreYouSureCancel] = useState(false);
  const [orders, setOrders] = useState({});

  async function closeEditOrderModal() {
    props.setShowEditOrderScreen(false);
  }

  // #region VOID ITEM(s)
  async function toggleVoid() {
    if (chargeOrder) {
      toast.error("You are already attempting to charge this order.");
    } else {
      if (!props.orderData.completedDate) {
        var v = document.getElementById("void");
        if (v.classList.contains("Active")) {
          v.classList.remove("Active");
        } else {
          v.classList.add("Active");
        }
        setVoidOrder((voidOrder) => {
          return !voidOrder;
        });
      } else {
        toast.error("Order already marked as completed.");
      }
    }
  }
  // #endregion
  //

  // #region EXTRA CHARGE(s)
  async function toggleChargeOrder() {
    if (voidOrder) {
      toast.error("You are already attempting to void items on this order.");
    } else {
      if (
        (props.orderData.completedDate && props.orderData.isVoided === 1) ||
        !props.orderData.completedDate
      ) {
        if (
          props.orderData.isVoided === 1 &&
          props.orderItemsData.length === 1
        ) {
          toast.error(
            "All items are voided there are no more items left to add an extra charge to."
          );
          return;
        }
        var edit = document.getElementById("charge");
        if (edit.classList.contains("Active")) {
          edit.classList.remove("Active");
        } else {
          edit.classList.add("Active");
        }
        setChargeOrder((chargeOrder) => {
          return !chargeOrder;
        });
      } else {
        toast.error("Order already marked as completed.");
      }
    }
  }
  // #endregion
  //

  async function reprintOrder() {
    setIsLoading(true);
    var orderGuid = CreateGuid();
    try {
      var orderData = {
        CustomerID: props.orderData.customerID,
        OrderNumber: props.orderData.orderNumber,
        OrderID: orderGuid,
        Order_UserID: props.orderData.order_UserID,
        OrderType: props.orderData.orderType,
        OrderedDate: props.orderData.orderedDate,
        RequestedDate: props.orderData.requestedDate,
        OrderStatusID: props.orderData.orderStatusID,
        OrderSubtotal: props.orderData.orderSubtotal.toFixed(2),
        OrderTax: props.orderData.orderTax,
        OrderTotal: props.orderData.orderTotal.toFixed(2),
        OrderTip: props.orderData.orderTip.toFixed(2),
        Fee: props.orderData.fee,
        DeliveryFee: props.orderData.deliveryFee,
        PhoneNumber: props.orderData.phoneNumber,
        Email: props.orderData.email,
        OrderETA: props.orderData.orderETA,
        DiscountedSubtotal: props.orderData.discountedSubtotal,
        DiscountedTax: props.orderData.discountedTax,
        DiscountedTotal: props.orderData.discountedTotal,
        Name: props.orderData.name,
        PercentageFee: props.orderData.percentageFee,
        Items: [
          {
            Extras: [],
          },
        ],
      };
      if (props.orderData.orderType === "Delivery") {
        orderData.OrderAddress = props.orderData.residence.residenceID;
        orderData.StreetAddress =
          props.orderData.residence.streetAddress +
          ", " +
          props.orderData.residence.city +
          " " +
          props.orderData.residence.stateName +
          " " +
          props.orderData.residence.zipCode;
      }
      for (var a = 0; a < props.orderItemsData.length; a++) {
        var newItemGuid = CreateGuid();
        orderData.Items[a] = {
          OrderItemID: newItemGuid,
          OrderItem_MenuItemID: props.orderItemsData[a].orderItem_MenuItemID,
          OrderItem_OrderID: props.orderItemsData[a].orderItem_OrderID,
          ItemQuantity: props.orderItemsData[a].itemQuantity,
          ItemTotalPrice: props.orderItemsData[a].itemTotalPrice,
          ItemSpecialRequests: props.orderItemsData[a].itemSpecialRequests,
          ItemName: props.orderItemsData[a].itemName,
          ItemPrice: props.orderItemsData[a].itemPrice,
          Extras: [],
        };
        if (props.orderItemsData[a].sizeCode) {
          orderData.Items[a].SizeCode = props.orderItemsData[
            a
          ].sizeCode.replace(/\((\w+)\)/, "$1");
        }
        for (var b = 0; b < props.orderItemsData[a].extras.length; b++) {
          orderData.Items[a].Extras[b] = {
            OrderExtra_OrderItemID: newItemGuid,
            OrderExtra_MenuItemExtraID:
              props.orderItemsData[a].extras[b].orderExtra_MenuItemExtraID,
            PizzaFirstHalf: props.orderItemsData[a].extras[b].pizzaFirstHalf,
            PizzaSecondHalf: props.orderItemsData[a].extras[b].pizzaSecondHalf,
            PizzaHalfCode: props.orderItemsData[a].extras[b].pizzaHalfCode,
            OrderExtraName: props.orderItemsData[a].extras[b].extraName,
            ExtraPrice: props.orderItemsData[a].extras[b].extraPrice,
          };
        }
      }

      var orderDataJson = JSON.stringify(orderData);
      await axios.post(`${BASE_API}ReprintOrder`, orderDataJson, {
        headers: {
          Authorization: `Bearer ${appContext.userInfo.token}`,
          "Content-Type": "application/json",
          Application: APPLICATION,
        },
      });
    } catch (err) {
      if (props.orderType === null) {
        toast.error("Please select 'Delivery' or 'Pickup'");
      } else {
        toast.error("Failed To Reprint Order");
      }
    }
    setIsLoading(false);
    toast.success("Order Successfully Reprinted!");
  }

  async function toggleAreYouSureCancel() {
    setOrders(props.orderData);
    setShowAreYouSureCancel(true);
  }

  return (
    <>
      {showAreYouSureCancel && (
        <AreYouSure
          getOpenOrders={props.getOpenOrders}
          orderData={orders}
          showAreYouSure={showAreYouSureCancel}
          setShowAreYouSure={setShowAreYouSureCancel}
          setShowEditOrderScreen={props.setShowEditOrderScreen}
          type={"cancel"}
        />
      )}
      {chargeOrder && (
        <ChargeOrderModal
          chargeOrder={chargeOrder}
          setChargeOrder={setChargeOrder}
          orderData={props.orderData}
          getOrderHistory={props.getOrderHistory}
          setShowEditOrderScreen={props.setShowEditOrderScreen}
        />
      )}

      {voidOrder && (
        <VoidAmountModal
          voidOrder={voidOrder}
          setVoidOrder={setVoidOrder}
          orderData={props.orderData}
          getOrderHistory={props.getOrderHistory}
          setShowEditOrderScreen={props.setShowEditOrderScreen}
        />
      )}

      <Modal className="editOrderModal" show={props.showEditOrderScreen}>
        <Modal.Body>
          <Modal.Header className="d-flex justify-content-end">
            <FaWindowClose
              onClick={closeEditOrderModal}
              style={{ width: "1.75em", height: "1.75em" }}
              className="text-danger"
            />
          </Modal.Header>
          <Modal.Title>
            <div className="orderNumber">
              Order #{props.orderData.orderNumber}
            </div>
            <div className="buttons">
              {isLoading ? (
                <div className="button">Printing...</div>
              ) : (
                <div className="button" onClick={reprintOrder}>
                  Print
                </div>
              )}

              {props.orderData.isVoided === 1 ||
                props.orderData.completedDate ||
                props.orderData.cancelledDate ? (
                <>
                  <div className="button cancel disabled">Cancel</div>
                </>
              ) : (
                <>
                  {props.orderData.payLater !== "true" && (
                    <>
                      <div id="cancel" className="button" onClick={() => toggleAreYouSureCancel()}>
                        Cancel
                      </div>
                    </>
                  )}
                </>
              )}
              {props.orderData.isEdited === 1 ||
                props.orderData.completedDate ||
                props.orderData.cancelledDate ? (
                <>
                  <div className="button disabled">Charge</div>
                </>
              ) : (
                <>
                  {props.orderData.payLater !== "true" && (
                    <>
                      <div
                        id="charge"
                        className="button"
                        onClick={toggleChargeOrder}
                      >
                        Charge
                      </div>
                    </>
                  )}
                </>
              )}
              {props.orderData.isVoided === 1 ||
                props.orderData.completedDate ||
                props.orderData.cancelledDate ? (
                <>
                  <div className="button disabled">Void</div>
                </>
              ) : (
                <>
                  {props.orderData.payLater !== "true" && (
                    <>
                      <div id="void" className="button" onClick={toggleVoid}>
                        Void
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Modal.Title>
          <div className="editOrder">
            <div className="left px-3 card">
              {props.orderItemsData?.map((i, index) => (
                <div className="order_item" key={index}>
                  <div className="item_name">
                    <div className="item_name_container">
                      <div className="quantity">{i.itemQuantity}x</div>
                      <div className="name">
                        {i.itemName === "" ? (
                          <>
                            {"$" + Math.abs(i.itemTotalPrice) + " Rewards Discount"}
                          </>
                        ) : (
                          <>
                            {i.itemName}
                          </>
                        )}
                      </div>
                      {i.sizeCode && i.sizeCode !== "" && (
                        <div className="size">({i.sizeCode})</div>
                      )}
                    </div>
                    {i.extras?.map((j, index2) => (
                      <div className="name extra_name">
                        - {j.extraName}{" "}
                        {j.pizzaHalfCode && " " + j.pizzaHalfCode}
                      </div>
                    ))}
                    {i.itemSpecialRequests && (
                      <div className="name extra_name request">
                        '{i.itemSpecialRequests}'
                      </div>
                    )}
                  </div>

                  <div className="item_price">
                    {props.orderData.discountedChargePercent ? (
                      <>
                        <div className="price">
                          {i.itemName === "" ? (
                            <>
                              -${Math.abs(i.itemTotalPrice).toFixed(2)}
                            </>
                          ) : (
                            <>
                              <s className="old_price">${i.itemPrice.toFixed(2)}</s>
                              $
                              {parseFloat(
                                (
                                  Math.round(
                                    i.itemPrice *
                                    props.orderData.discountedChargePercent *
                                    100
                                  ) / 100
                                ).toFixed(2)
                              ).toFixed(2)}
                            </>
                          )}
                        </div>
                        {i.extras?.map((j, index2) => (
                          <div className="extra_price">
                            <s className="old_price">
                              ${j.extraPrice.toFixed(2)}
                            </s>
                            $
                            {parseFloat(
                              (
                                Math.round(
                                  j.extraPrice *
                                  props.orderData.discountedChargePercent *
                                  100
                                ) / 100
                              ).toFixed(2)
                            ).toFixed(2)}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {i.itemName === null ? (<div className="price">${i.itemTotalPrice.toFixed(2)}</div>) : (<div className="price">${i.itemPrice.toFixed(2)}</div>)}
                        {i.extras?.map((j, index2) => (
                          <div className="extra_price">${j.extraPrice}</div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="right">
              <div className="order_details card">
                {props.orderData.discountedChargePercent ? (
                  <div className="card-header">
                    Order Details
                    <div className="discount">
                      {(
                        (1 - props.orderData.discountedChargePercent) *
                        100
                      ).toFixed()}
                      % off
                    </div>
                  </div>
                ) : (
                  <div className="card-header">Order Details</div>
                )}

                <div className="card-body">
                  {props.orderData.payLater && (
                    <div className="payLater">
                      <b>Pay at {props.orderData.orderType}</b>
                    </div>
                  )}
                  <div className="user_details">
                    <div className="desc">
                      Name: <b>{props.orderData.name}</b>
                    </div>
                    <div className="desc">
                      Phone: <b>{props.orderData.phoneNumber}</b>
                    </div>
                  </div>
                  <div className="order_details">
                    {props.orderData.discountedTotal ? (
                      <>
                        <div className="desc">
                          Subtotal: <b>${props.orderData.discountedSubtotal}</b>{" "}
                          <s>${props.orderData.orderSubtotal}</s>
                        </div>
                        <div className="desc">
                          Tax: <b>${props.orderData.discountedTax}</b>{" "}
                          <s>${props.orderData.orderTax}</s>
                        </div>
                        <div className="desc">
                          Tip: <b>${props.orderData.orderTip}</b>
                        </div>
                        <div className="desc">
                          Service Fee: <b>${props.orderData.fee.toFixed(2)}</b>
                        </div>
                        {props.orderData.percentageFee > 0 && (
                          <div className="desc">
                            Card Processing Fee:{" "}
                            <b>${props.orderData.percentageFee.toFixed(2)}</b>
                          </div>
                        )}
                        {props.orderData.orderType === "Delivery" && (
                          <div className="desc">
                            Delivery Fee:{" "}
                            <b>${props.orderData.deliveryFee.toFixed(2)}</b>
                          </div>
                        )}
                        {(props.orderData.isVoided ||
                          props.orderData.isEdited) && (
                            <div className="desc">
                              Total:{" "}
                              <b className="edited">
                                ${props.orderData.otherTotal.toFixed(2)}
                              </b>{" "}
                              <s>${props.orderData.discountedTotal.toFixed(2)}</s>
                            </div>
                          )}
                        {!props.orderData.isEdited &&
                          !props.orderData.isVoided && (
                            <div className="desc">
                              Total:{" "}
                              <b>
                                ${props.orderData.discountedTotal.toFixed(2)}
                              </b>{" "}
                              <s>${props.orderData.orderTotal.toFixed(2)}</s>
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="desc">
                          Subtotal: <b>${props.orderData.orderSubtotal}</b>
                        </div>
                        <div className="desc">
                          Tax: <b>${props.orderData.orderTax}</b>
                        </div>
                        <div className="desc">
                          Tip: <b>${props.orderData.orderTip}</b>
                        </div>
                        <div className="desc">
                          Service Fee: <b>${props.orderData.fee.toFixed(2)}</b>
                        </div>
                        {props.orderData.percentageFee > 0 && (
                          <div className="desc">
                            Card Processing Fee:{" "}
                            <b>${props.orderData.percentageFee.toFixed(2)}</b>
                          </div>
                        )}
                        {props.orderData.orderType === "Delivery" && (
                          <div className="desc">
                            Delivery Fee:{" "}
                            <b>${props.orderData.deliveryFee.toFixed(2)}</b>
                          </div>
                        )}
                        {props.orderData.isVoided ||
                          props.orderData.isEdited ? (
                          <div className="desc">
                            Total:{" "}
                            <b className="edited">
                              ${props.orderData.otherTotal.toFixed(2)}
                            </b>{" "}
                            <s>${props.orderData.orderTotal.toFixed(2)}</s>
                          </div>
                        ) : (
                          <>
                            <div className="desc">
                              Total:{" "}
                              <b>${props.orderData.orderTotal.toFixed(2)}</b>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="order_time">
                    {props.orderData.orderType === "Delivery" && (
                      <div className="address d-flex flex-column">
                        <div className="desc">Address:</div>
                        <div className="desc">
                          <b>
                            {props.orderData.residence.streetAddress},{" "}
                            {props.orderData.residence.city}{" "}
                            {props.orderData.residence.stateName}{" "}
                            {props.orderData.residence.zipCode}
                          </b>
                        </div>
                      </div>
                    )}
                    <div className="orderType">
                      {props.orderData.orderType.toUpperCase()} at
                    </div>
                    <div className="orderType">
                      {new Date(props.orderData.requestedDate).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditOrderModal;
